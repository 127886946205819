import React from "react";

import { Layout } from "../components/parts/Layout";
import { Header } from "../components/parts/Header";
import { Functions } from "../components/Functions";
import { Footer } from "../components/Footer";

const FunctionsPage = () => {
  return (
    <Layout>
      <Header
        title={"機能一覧"}
        description={"機能一覧"}
      />
      <Functions />
      <Footer />
    </Layout>
  )
}

export default FunctionsPage

import React from "react";
import styled from "styled-components";

import { Free } from "../components/Free";

import { Container } from "../components/parts/Container";
import { Button } from "../components/parts/Button";
import { WhiteWrapper } from "../components/parts/WhiteWrapper";
import { TitleView } from "./parts/TitleView";

import { Responsive, Text, FontSize, Color } from "../utils/Const";

import imgCSV from "../images/csv_set.jpg";
import imgDD from "../images/csv-anime.gif";
import imgTimeCard from "../images/time_card.jpg";
import imgMsg from "../images/msg.jpg";
import imgMessageRead from "../images/message_read.png";
import imgPub from "../images/msg_public.jpg";
import imgStaffMng from "../images/staff_mng.png";
import imgStaffCsv from "../images/staff_csv.png";
import imgKintaiList from "../images/kintai_list.png"
import imgKintaiListStaff from "../images/kintai_list_staff.png"
import imgKintaiListStaffPrint from "../images/kintai_list_staff_print.png"
import imgKintaiSearch from "../images/kintai_search.png"
import imgKintaiSettingTime from "../images/kintai_setting_time.png"
import imgKintaiSettingBusyo from "../images/kintai_setting_busyo.png"
import imgKintaiSettingType from "../images/kintai_setting_type.png"
import imgSettingBackground from "../images/setting_background.png"
import imgSettingBackgroundSample from "../images/setting_background_sample.png"
import imgSettingChangeTime from "../images/setting_change_time.png"
import imgSettingClosingDay from "../images/setting_closing_day.png"
import imgSettingCalendar from "../images/setting_calendar.png"
import imgSettingCalendarSample from "../images/setting_calendar_sample.png"
import imgSettingQR from "../images/setting_qr.png"

const Description = styled.p`
  width: 100%;
  margin: 1.0rem 0;
  font-size: ${FontSize.sm};
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 2rem 0 2rem;
`

const SystemTitle = styled.h2`
  width: 100%;
  margin: 5rem 0 1rem;
  color: ${Color.Orange};
  font-size: ${FontSize.lg};
  font-weight: normal;
  text-align: center;
`

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div:nth-child(odd) {
    // 交互にひっくり返したい場合
    //flex-direction: row-reverse;
  }
`

const CardTitle = styled.h3`
  width: 100%;
  margin: 3.5rem 0 0.75rem;
  padding: 0rem 1rem;
  box-sizing: border-box;
  border-left: 4px solid ${Color.Orange};
  color: ${Color.Black};
  font-size: ${FontSize.md};
  font-weight: normal;
  text-align: left;
`

const CardContentWrapper = styled.div`
  display: flex;
  margin: 1.5rem 0;
  padding: 2rem 0;
  border-radius: 2rem;
  background-color: ${Color.Yellow};
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
  }
`

const CardImgWrapper = styled.div`
  width: 100%;
  padding: 2rem 2rem;
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  @media(max-width: ${Responsive.SP}px) {
    padding: 2rem 0;
  }
`

const CardImg = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 400px;
  margin: 1rem 0;
  box-shadow: 0 0 1.0rem 0.1rem ${Color.Border};
`

const CardTextWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start-flex;
  padding: 2rem;
  box-sizing: border-box;
  @media(max-width: ${Responsive.SP}px) {
    max-width: 100%;
    padding: 2rem 0;
  }
`

const CardDescription = styled.p`
  width: 100%;
  margin: 0;
  padding: 1.5rem 0.5rem;
  color: ${Color.Black};
  font-size: ${FontSize.sm};
  font-weight: normal;
  text-align: left;
`

const CardContent = ({img, descriptions}) => {
  return (
    <CardContentWrapper>
      <CardImgWrapper>
        <CardImg src={img} />
      </CardImgWrapper>
      <CardTextWrapper>
        {
          descriptions.map((description) => <CardDescription>{description}</CardDescription>)
        }
      </CardTextWrapper>
    </CardContentWrapper>
  )
}

export const Functions = () => {
  return(
    <>
    <WhiteWrapper>
      <Container>
        <TitleView>機能一覧</TitleView>
        <Description>{Text.SERVICE_NAME}で利用できる機能を一部、ご紹介します。</Description>
        <Description>詳しくは、デモで各機能をお試しください。</Description>

        <ButtonWrapper>
          <Button href={`https://demo.otsutome.net/demo/login`}>デモを使ってみる</Button>
        </ButtonWrapper>

        <SystemTitle>管理者向け機能一覧</SystemTitle>

        <CardWrapper>
          <CardTitle>スタッフ管理</CardTitle>
          <CardContent
            img={imgStaffMng}
            descriptions={[
              `スタッフ（従業員）を管理できます。`,
              `スタッフ毎に、権限・部署・雇用形態・締め日など細かい設定をすることが可能です。`,
            ]}
          />
          <CardContent
            img={imgStaffCsv}
            descriptions={[
              `CSVデータを利用して、スタッフ一括登録や修正も対応しています。大人数の登録も簡単です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>勤怠管理</CardTitle>
          <CardContent
            img={imgKintaiSearch}
            descriptions={[
              `管理者は、スタッフの勤怠を管理できます。`,
              `検索機能も備わっているので、確認したい日付の勤怠データもすぐに確認することができます。`,
            ]}
          />
          <CardContent
            img={imgKintaiList}
            descriptions={[
              `スタッフの勤怠を一覧で確認できます。締め日表示にも対応しており、総勤務時間や残業時間、休憩時間も計算されて表示されます。`,
              `専用の印刷ページもあるため、プリンタに出力し提出や配布することも可能です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>勤怠データCSV出力</CardTitle>
          <CardContent
            img={imgCSV}
            descriptions={[
              `勤怠データをCSVファイルとして出力することができます。現在ご利用中の外部給与システムなどのお好きなソフトウェアとの連携が可能です。`,
              `OS（Windows / macOS / Linux）や文字コード、改行コード、日付の形式などさまざまな環境やフォーマットにも対応しています。`,
            ]}
          />
          <CardContent
            img={imgDD}
            descriptions={[
              `出力する項目も自由に選択可能で、並び替えは「ドラッグ＆ドロップ」による簡単操作で自由自在です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>時間の設定</CardTitle>
          <CardContent
            img={imgKintaiSettingTime}
            descriptions={[
              `時間の設定では、勤務時間のスケジュールを登録できます。`,
              `登録したスケジュールで、休憩や残業などの計算を自動で行います。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>部署の設定</CardTitle>
          <CardContent
            img={imgKintaiSettingBusyo}
            descriptions={[
              `部署の管理をすることができます。`,
              `スタッフを部署に所属させたり、部署宛てにメッセージを送信することが可能です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>雇用形態の設定</CardTitle>
          <CardContent
            img={imgKintaiSettingType}
            descriptions={[
              `雇用形態の管理をすることができます。`,
              `デフォルトで用意されている雇用契約以外の、任意の独自の雇用形態も追加することが可能です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>デザインのカスタマイズ</CardTitle>
          <CardContent
            img={imgSettingBackground}
            descriptions={[
              `お好きな壁紙を設定することができます。`,
            ]}
          />
          <CardContent
            img={imgSettingBackgroundSample}
            descriptions={[
              `企業やお店、チームの独自カラーを出し、帰属意識を高めることが可能です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>切替時間・出勤時間の設定</CardTitle>
          <CardContent
            img={imgSettingChangeTime}
            descriptions={[
              `1日の切替時間（締め時間）と出勤時間を設定することができます。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>締め日の設定</CardTitle>
          <CardContent
            img={imgSettingClosingDay}
            descriptions={[
              `締め日を設定することができます。`,
              `会社として標準の締め日だけでなく、スタッフに応じて個別の締め日を設定することも可能です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>カレンダーの設定</CardTitle>
          <CardContent
            img={imgSettingCalendar}
            descriptions={[
              `カレンダーを設定することができます。`,
            ]}
          />
          <CardContent
            img={imgSettingCalendarSample}
            descriptions={[
              `会社やお店などの独自カレンダーを登録することで、従業員と共有することが可能です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>QRコード</CardTitle>
          <CardContent
            img={imgSettingQR}
            descriptions={[
              `ログイン用のQRコードを作成することができます。`,
              `社内グループウェアや紙に印刷しておけば、どこからでも簡単にアクセスすることが可能です。`,
            ]}
          />
        </CardWrapper>

        <SystemTitle>スタッフ向け機能一覧</SystemTitle>

        <CardWrapper>
          <CardTitle>タイムカード</CardTitle>
          <CardContent
            img={imgTimeCard}
            descriptions={[
              `ボタン一つでポチっと簡単に勤怠入力できます。`,
              `タイムカード要らずで、パソコン・スマホ・タブレットでらくらく出退勤の登録が可能で、リモートワークにもオススメです。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>勤怠管理</CardTitle>
          <CardContent
            img={imgKintaiListStaff}
            descriptions={[
              `登録した勤怠を簡単に一覧で確認できます。`,
            ]}
          />
          <CardContent
            img={imgKintaiListStaffPrint}
            descriptions={[
              `管理者と同様に印刷することが可能です。`,
            ]}
          />
        </CardWrapper>

        <CardWrapper>
          <CardTitle>メッセージ</CardTitle>
          <CardContent
            img={imgMsg}
            descriptions={[
              `全体や部署、個人間に対して、メッセージを送信することが可能です。`,
            ]}
          />
          <CardContent
            img={imgPub}
            descriptions={[
              `重要度の設定や、部署・雇用形態による「閲覧制限」も設定することが可能です。`,
            ]}
          />
          <CardContent
            img={imgMessageRead}
            descriptions={[
              `既読者も分かるため、未読の人に注意を促すことが可能です。`,
            ]}
          />
        </CardWrapper>

      </Container>
    </WhiteWrapper>
    <Free />
    </>
  );
}